/* eslint-disable import/no-unresolved */
import 'virtual:windi-base.css';
import 'virtual:windi-components.css';
import '../css/styles.css';
import 'virtual:windi-utilities.css';
import 'tippy.js/dist/tippy.css';
import './utils/sentry';
import './utils/track';
import { documentReady } from './utils/documentReady';
import { initModules } from './modules';
import { updateQueryString } from './utils/history';
import { onDelegateEvent } from './utils/dom';

documentReady(() => {
  window.onReady.ready = true;
  window.onReady.stack.forEach((cb) =>
    cb({
      onDelegateEvent,
    })
  );
});

updateQueryString({
  login_token: '',
  ref: '',
});

initModules();
