import { path } from './url';
import { Action } from '../auth/types';

export const FacebookEvents = {
  track(
    eventName: string,
    eventParams: unknown = {},
    eventData: unknown = {}
  ): void {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).fbq('track', eventName, eventParams, eventData);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('fb track', {
        eventName,
        eventParams,
        eventData,
      });
    }
  },
  trackCustom(
    eventName: string,
    eventParams: unknown = {},
    eventData: unknown = {}
  ): void {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).fbq('trackCustom', eventName, eventParams, eventData);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('fb trackCustom', { eventName, eventParams, eventData });
    }
  },
};

window.FacebookEvents = FacebookEvents;

export const GAnalytics = {
  track(event: string, params?: Record<string, unknown>): void {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).gtag('event', event, params);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('analytics track', event, params);
    }
  },
  trackUA(
    eventCategory: string,
    eventAction: string,
    eventLabel: string,
    eventValue?: string | number,
    extraFields?: Record<string, unknown>
  ): void {
    const fieldsObject: {
      hitType: string;
      eventCategory: string;
      eventAction: string;
      eventLabel: string;
      eventValue?: string;
    } = {
      hitType: 'event',
      eventCategory,
      eventAction,
      eventLabel,
      ...extraFields,
    };

    if (typeof eventValue !== 'undefined') {
      fieldsObject.eventValue = eventValue.toString();
    }

    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).ga('send', fieldsObject);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('analytics track', fieldsObject);
    }
  },
  labelFromAction(action?: Action): string {
    if (!action?.type) {
      return 'NONE';
    }

    switch (action.type) {
      case 'funnel':
        return 'AUCTION_FUNNEL';
      case 'auctionBuyNow':
        return 'AUCTION_BUY_NOW';
      case 'live':
        return 'LIVE_FUNNEL';
      case 'follow':
        return 'FOLLOW';
      case 'sweepstakes':
        return 'SWEEP';
      case 'donation':
        return 'NONE';
      case 'promocode':
        return 'PROMO_POPUP';
      case 'header':
        return 'HEADER_SIGNUP';
      case 'helpcontact':
        return 'HELPCONTACT_SIGNUP';
    }
  },
};

window.GAnalytics = GAnalytics;

export const ShareASale = {
  track(
    orderId: string,
    xtype: string,
    orderAmount: number,
    orderCurrency: string,
    extraFields?: Record<string, unknown>
  ): void {
    const fieldsObject = {
      merchantID: '106247',
      transtype: 'sale',
      tracking: orderId,
      xtype,
      amount: orderAmount.toFixed(2),
      currency: orderCurrency,
      ...extraFields,
    };

    const trackUrl = path('https://www.shareasale.com/sale.cfm', fieldsObject);

    if (!window.DEV && !window.DEV_SERVER) {
      const image = document.createElement('img');
      image.src = trackUrl;
      image.width = 1;
      image.height = 1;
      document.body.appendChild(image);
      image.style.display = 'none';
    } else {
      // eslint-disable-next-line no-console
      console.log('shareasale track', fieldsObject);
    }
  },
};

window.ShareASale = ShareASale;

// export const TikTokEvents = {
//   track(eventName: string, eventParams: unknown = {}): void {
//     try {
//       // eslint-disable-next-line @typescript-eslint/no-explicit-any
//       (window as any).ttq.track(eventName, eventParams);
//     } catch (e) {
//       // eslint-disable-next-line no-console
//       console.log('tiktok track', { eventName, eventParams });
//     }
//   },
// };
//
// window.TikTokEvents = TikTokEvents;

export const RedditEvents = {
  track(eventName: string): void {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).rdt('track', eventName);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('reddit track', { eventName });
    }
  },
};

window.RedditEvents = RedditEvents;

export const TwitterEvents = {
  track(eventName: string, eventParams: unknown = {}): void {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).twq('track', eventName, eventParams);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('reddit track', { eventName });
    }
  },
};

window.TwitterEvents = TwitterEvents;
