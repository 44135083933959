function querySelector(
  parent: HTMLElement | Document
): <T extends HTMLElement>(selector: string) => T;
function querySelector<T extends HTMLElement>(
  parent: HTMLElement | Document,
  selector: string
): T;
function querySelector<T extends HTMLElement>(selector: string): T;
function querySelector<T extends HTMLElement>(
  parent: HTMLElement | Document | string,
  selector?: string
): T | (<T2 extends HTMLElement>(selector: string) => T2) {
  const parentElement = typeof parent === 'string' ? document : parent;
  const querySelector = typeof parent === 'string' ? parent : selector;

  const query = <R extends HTMLElement>(selector: string): R => {
    const el = selector.match(/^#/)
      ? ((document.getElementById(selector.slice(1)) as unknown) as R | null)
      : parentElement.querySelector<R>(selector);

    if (!el) {
      throw new Error(`Selector ${selector} didn't match any elements.`);
    }

    return el;
  };

  if (typeof querySelector !== 'undefined') {
    return query<T>(querySelector);
  }

  return query;
}

function querySelectorAll(
  parent: HTMLElement
): <T extends HTMLElement>(selector: string) => NodeListOf<T>;
function querySelectorAll<T extends HTMLElement>(
  parent: HTMLElement,
  selector: string
): NodeListOf<T>;
function querySelectorAll<T extends HTMLElement>(
  selector: string
): NodeListOf<T>;
function querySelectorAll<T1 extends HTMLElement>(
  parent: HTMLElement | string,
  selector?: string
):
  | NodeListOf<T1>
  | (<T2 extends HTMLElement>(selector: string) => NodeListOf<T2>) {
  const parentElement = typeof parent === 'string' ? document : parent;
  const querySelector = typeof parent === 'string' ? parent : selector;

  const query = <T3 extends HTMLElement>(selector: string): NodeListOf<T3> =>
    parentElement.querySelectorAll<T3>(selector);

  if (typeof querySelector !== 'undefined') {
    return query(querySelector);
  }

  return query;
}

function toggleClass(
  element: HTMLElement,
  classes: Record<string, boolean> | string | string[],
  force?: boolean
): void {
  let classesRecord: Record<string, boolean | undefined> = {};

  if (typeof classes === 'object' && !Array.isArray(classes)) {
    classesRecord = classes;
  } else {
    (typeof classes === 'string' ? classes.split(' ') : classes).forEach(
      (className) => {
        const cleanClass = className.trim();

        if (cleanClass) {
          classesRecord[cleanClass] = force;
        }
      }
    );
  }

  Object.entries(classesRecord).forEach(([className, forceToggle]) => {
    element.classList.toggle(className, forceToggle);
  });
}

function addClass(element: HTMLElement, classes: string | string[]): void {
  toggleClass(element, classes, true);
}

function removeClass(element: HTMLElement, classes: string | string[]): void {
  toggleClass(element, classes, false);
}

function onEvent<Type extends keyof HTMLElementEventMap>(
  element: HTMLElement | Document,
  type: Type,
  listener: (event: DocumentEventMap[Type]) => void,
  options?: boolean | AddEventListenerOptions
): void;
function onEvent(
  element: HTMLElement | Document,
  type: string,
  listener: EventListener,
  options?: boolean | AddEventListenerOptions
): void;
function onEvent(
  element: HTMLElement | Document,
  type: string,
  listener: EventListener,
  options?: boolean | AddEventListenerOptions
): void {
  type.split(' ').forEach((eventType) => {
    element.addEventListener(eventType, listener, options);
  });
}

function offEvent(
  element: HTMLElement | Document,
  type: keyof HTMLElementEventMap | string,
  listener: EventListener,
  options?: boolean | AddEventListenerOptions
): void {
  type.split(' ').forEach((eventType) => {
    element.removeEventListener(eventType, listener, options);
  });
}

function onDelegateEvent<Type extends keyof HTMLElementEventMap>(
  element: HTMLElement | Document,
  type: Type,
  selector: string,
  listener: (
    event: DocumentEventMap[Type] & { delegateTarget: Element }
  ) => void,
  options?: boolean | AddEventListenerOptions
): void;
function onDelegateEvent(
  element: HTMLElement | Document,
  type: string,
  selector: string,
  listener: (evt: Event & { delegateTarget: Element }) => void,
  options?: boolean | AddEventListenerOptions
): void {
  const safeClosest = (event: Event): Element | void => {
    // eslint-disable-next-line prefer-destructuring
    let target = event.target;

    if (target instanceof Text) {
      target = target.parentElement;
    }

    if (target instanceof Element && event.currentTarget instanceof Element) {
      const closest = target.closest(selector);

      if (closest && event.currentTarget.contains(closest)) {
        return closest;
      }
    }
  };

  let nativeListenerOptions: boolean | AddEventListenerOptions | undefined;
  let once = false;

  if (typeof options === 'boolean') {
    nativeListenerOptions = options;
  } else if (options) {
    ({ once = false, ...nativeListenerOptions } = options);
  }

  return onEvent(
    element,
    type,
    (event) => {
      const delegateTarget = safeClosest(event);

      if (delegateTarget) {
        const delegateEvent = Object.assign(event, { delegateTarget });

        listener(delegateEvent);

        if (once) {
          offEvent(
            element,
            type,
            listener as EventListener,
            nativeListenerOptions
          );
        }
      }
    },
    nativeListenerOptions
  );
}

export {
  querySelector,
  querySelectorAll,
  toggleClass,
  addClass,
  removeClass,
  onEvent,
  offEvent,
  onDelegateEvent,
};
