import { documentReady } from './utils/documentReady';
import { setPreload } from './utils/preload';
import { assert } from './utils/assert';

interface ModuleConfig {
  cache: boolean;
}

interface Module<T extends HTMLElement> {
  module: (el: T) => void;
  config: ModuleConfig;
  type: 'Module';
}

interface Namespace {
  loadModule: (dataName: string, el: HTMLElement) => void;
  config: ModuleConfig;
  type: 'Namespace';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isModule<T extends HTMLElement>(data: any): data is Module<T> {
  return typeof data === 'object' && data.type === 'Module';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isNamespace(data: any): data is Namespace {
  return typeof data === 'object' && data.type === 'Namespace';
}

type Modules = Record<string, () => Promise<Record<string, unknown>>>;

function getNameParts(dataName: string): { namespace: string; name: string } {
  const module = dataName.split(':');
  const namespace = module.length === 2 ? module[0] : 'root';
  const name = module.length === 2 ? module[1] : module[0];

  return {
    namespace,
    name,
  };
}

function loadModules(config: {
  getPath: (namespace: string, name: string) => string;
  modules: Modules;
}): (dataName: string, el: HTMLElement) => void {
  const cache: Record<string, boolean> = {};

  return (dataName, el) => {
    const { namespace, name } = getNameParts(dataName);

    if (!name || !namespace) {
      throw new Error(
        !namespace ? 'Module namespace missing' : 'Module missing'
      );
    }

    const modulePath = config.getPath(namespace, name);
    const moduleFn = config.modules[modulePath];

    if (typeof moduleFn === 'undefined') {
      throw new Error(`Module ${dataName} not found!`);
    }

    if (cache[dataName]) {
      return;
    }

    moduleFn()
      .then((moduleChunk) => {
        const moduleExport =
          moduleChunk[name] || moduleChunk[namespace] || moduleChunk.default;

        if (cache[dataName]) {
          return;
        }

        if (!isModule(moduleExport) && !isNamespace(moduleExport)) {
          if (typeof moduleExport === 'function') {
            moduleExport(el);
          }

          return;
        }

        cache[dataName] = moduleExport.config.cache;

        if (isNamespace(moduleExport)) {
          moduleExport.loadModule(dataName, el);
          return;
        }

        moduleExport.module(el);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e); 
      });
  };
}

export function createModule<T extends HTMLElement = HTMLElement>(
  module: (el: T) => void,
  config?: ModuleConfig
): Module<T> {
  return {
    module,
    config: {
      cache: config?.cache ?? true,
    },
    type: 'Module',
  };
}

export function createNamespace(
  modules: Record<
    string,
    ((element: HTMLElement) => void) | Record<string, unknown>
  >,
  getPath?: (name: string) => string
): Namespace {
  const loadModule = loadModules({
    getPath: (namespace, name) => (getPath ? getPath(name) : name),
    modules: Object.keys(modules).reduce((newModules, moduleName) => {
      const module = modules[moduleName];
      const exports =
        typeof module === 'function'
          ? {
              [moduleName]: module,
            }
          : module;

      return {
        ...newModules,
        [moduleName]: () => Promise.resolve(exports),
      };
    }, {} as Modules),
  });

  return {
    loadModule,
    config: {
      cache: false,
    },
    type: 'Namespace',
  };
}

export function initModules(): void {
  const loadModule = loadModules({
    getPath(namespace, name) {
      return `./modules/${namespace === 'root' ? name : namespace}.ts`;
    },
    modules: import.meta.glob<Record<string, unknown>>('./modules/*.ts'),
  });

  documentReady(() => {
    document.querySelectorAll<HTMLElement>('[data-module]').forEach((el) => {
      const dataModules = el.getAttribute('data-module')?.split(',');

      if (!dataModules) {
        return;
      }

      dataModules.forEach((dataModule) => {
        if (dataModule === 'preload') {
          assert(el.dataset.key, 'Missing key');
          setPreload(el.dataset.key, JSON.parse(el.innerText));
          el.remove();
        } else {
          loadModule(dataModule, el);
        }
      });
    });
  });
}
