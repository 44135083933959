export type SitePath = string;

type AbsolutePlatform = 'platform' & {
  readonly __b: unique symbol;
};

const platform = 'platform' as AbsolutePlatform;

const isAbsolutePlatform = (path: unknown): path is AbsolutePlatform =>
  path === platform;

type AbsolutePath = boolean | AbsolutePlatform;

function getPath(
  url: string,
  urlParams?: Record<string, string | number | boolean | undefined>,
  absolute?: AbsolutePath
): SitePath {
  const cleanUrl = url.length && url[0] === '/' ? url.slice(1) : url;
  const external = cleanUrl.indexOf('http') > -1;
  const path = !external ? `${window.WBASE}/${cleanUrl}` : cleanUrl;
  const host =
    // eslint-disable-next-line no-nested-ternary
    !external && absolute
      ? isAbsolutePlatform(absolute)
        ? window.PLATFORM_HOST
        : window.HOST
      : '';

  if (urlParams) {
    const normalizedParams = new URLSearchParams(
      Object.keys(urlParams).reduce((params, param) => {
        const paramValue = urlParams[param];

        if (paramValue === undefined) {
          return params;
        }

        return {
          ...params,
          [param]: String(paramValue),
        };
      }, {})
    ).toString();

    if (normalizedParams.length) {
      return `${host}${path}?${normalizedParams}`;
    }
  }

  return `${host}${path}`;
}

export function path(url: string, absolute?: boolean): SitePath;
export function path(
  url: string,
  urlParams?: Record<string, string | number | boolean | undefined>,
  absolute?: boolean
): SitePath;
export function path(
  url: string,
  urlParams:
    | boolean
    | Record<string, string | number | boolean | undefined> = false,
  absolute = false
): SitePath {
  const full = typeof urlParams === 'boolean' ? urlParams : absolute;
  return getPath(
    url,
    typeof urlParams === 'boolean' ? undefined : urlParams,
    full
  );
}

export function platformPath(
  url: string,
  urlParams?: Record<string, string | number | boolean | undefined>
): SitePath {
  return getPath(url, urlParams, platform);
}
