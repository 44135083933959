import * as Sentry from '@sentry/react';

Sentry.init({
  dsn:
    'https://3fe0ec741d95801755aac74a6ed7dd42@o153684.ingest.sentry.io/4505971314130944',
  environment: window.DEV_SERVER ? 'development' : 'production',
});

if (window.IS_LOGGED) {
  Sentry.setUser({ id: window.LOGGED_UID });
}
