import { assert } from './assert';

let cache: Map<string, unknown> = new Map();

if (window.sessionStorage.preloadCache) {
  cache = new Map(JSON.parse(window.sessionStorage.preloadCache));
}

export const getPreloaded = <T>(key: string, fallback?: T): T => {
  assert(
    typeof fallback !== 'undefined' || cache.has(key),
    `Missing preloaded ${key}`
  );

  return cache.has(key) ? (cache.get(key) as T) : (fallback as T);
};

export const setPreload = (key: string, value: unknown): void => {
  cache.set(key, value);
  window.sessionStorage.setItem(
    'preloadCache',
    JSON.stringify(Array.from(cache.entries()))
  );
};
