export function updateQueryString(
  params: Record<string, string | number>
): void {
  const searchParams = new URLSearchParams(window.location.search);
  const initialSearchParams = searchParams.toString();

  Object.keys(params).forEach((param) => {
    if (params[param] === '') {
      searchParams.delete(param);
    } else {
      searchParams.set(param, String(params[param]));
    }
  });

  const searchParamsString = searchParams.toString();

  if (initialSearchParams === searchParamsString) {
    return;
  }

  window.history.replaceState(
    {},
    '',
    window.location.pathname +
      (searchParamsString.length ? '?' : '') +
      searchParamsString +
      window.location.hash
  );
}
